<template>
  <mi-list-item class="q-py-md">
    <mi-list-item-section>
      <!-- Component variant ID&Name or ID2&Name for old validations -->
      <mi-list-item-label
        v-if="validation"
        class="text-weight-bold q-mb-md"
        lines="3"
      >
        <a
          class="validation-explanation-item__link validation-explanation-item__hover text-subtitle2 text-weight-bold"
          role="button"
          tabindex="0"
          @click="redirectToWindchill(item.ambiguous1Id)"
        >
          {{ item.ambiguous1Id2 }} {{ item.ambiguous1Name }}
        </a>
        -
        <a
          class="validation-explanation-item__link validation-explanation-item__hover text-subtitle2 text-weight-bold"
          role="button"
          tabindex="0"
          @click="redirectToWindchill(item.ambiguous2Id)"
        >
          {{ item.ambiguous2Id2 }} {{ item.ambiguous2Name }}
        </a>
      </mi-list-item-label>
      <mi-list-item-label
        v-else
        class="text-weight-bold q-mb-md"
        lines="3"
      >
        {{ item.ambiguous1Id2 }} {{ item.ambiguous1Name }} -
        {{ item.ambiguous2Id2 }} {{ item.ambiguous2Name }}
      </mi-list-item-label>
      <!-- Explanation -->
      <div class="text-weight-light"> {{ item.explanation }} </div>

      <!-- Planning period range -->
      <mi-list-item-label class="q-mt-md" caption>
        {{ item.rangeFrom }} - {{ item.rangeTo }}
      </mi-list-item-label>
    </mi-list-item-section>
  </mi-list-item>
</template>

<script>
  import { createWindchillLink } from '@/utils/windchill'
  import useFeatureToggle from '@/composables/featureToggle'
  import { FEATURES } from '@/utils/featureToggle'
  import { BOM_SHOW_AMBIGUOUS_LINK } from '@/constants'

  export default {
    name: 'BomValidationResultsElementsAmbiguous',
    props: {
      item: {
        type: Object,
        required: true
      }
    },
    setup() {
      const { isEnabled: showLinkEnabled } = useFeatureToggle(
        FEATURES.VUE_APP_BOM_SHOW_AMBIGUOUS_TOGGLE
      )
      return { showLinkEnabled }
    },
    computed: {
      validation() {
        return this.item.ambiguous1Id && this.showLinkEnabled
      }
    },
    methods: {
      redirectToWindchill(ruleId) {
        const windchillLink = createWindchillLink(BOM_SHOW_AMBIGUOUS_LINK, ruleId)
        if (windchillLink) window.open(windchillLink, '_blank')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .validation-explanation {
    gap: .5rem;

    &__card {
      border-color: $mi-anthracite-100;
    }

    &__list {
      list-style-type: none;
    }
  }

  .validation-explanation-item.mi-list-item {
    min-height: auto;
  }

  .validation-explanation-item__title {
    min-width: 100px;
  }

  .validation-explanation-item__link {
    color: $mi-anthracite-800;
    margin-top: 0;
    margin-bottom: 0;
    text-decoration: none;
  }

  .validation-explanation-item__hover {
    &:hover {
      color: $mi-red-500;
      cursor: pointer;
      text-decoration: underline;
    }
  }
</style>
